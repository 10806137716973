var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"base-page"},[_c('PageHeader',{staticClass:"base-shadow"}),_c('keep-alive',[_c('FilterSection',{on:{"change":_vm.onFilterChange}})],1),_c('section',{staticClass:"table-wrap base-shadow"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"700px","element-loading-text":_vm.laodingText}},[_c('el-table-column',{attrs:{"prop":"departmentName","label":"部门","width":"100","fixed":"left","align":"center"}}),_c('el-table-column',{attrs:{"prop":"ownerName","label":"负责人","width":"100","fixed":"left","align":"center"}}),_vm._l((_vm.stageCoulmns),function(item,index){return _c('el-table-column',{key:`${item.stageId}-${index}`,attrs:{"prop":item.stageName,"label":item.stageName}},[_c('el-table-column',{attrs:{"prop":"count","label":`${item.stageName}新增`,"min-width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-nav",on:{"click":function($event){return _vm.onShowClientListDetail(
                  scope.row,
                  scope.row.stageStatistics[index],
                  'count'
                )}}},[_vm._v(_vm._s(scope.row.stageStatistics[index].count))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"total","label":`${item.stageName}合计`,"min-width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-nav",on:{"click":function($event){return _vm.onShowClientListDetail(
                  scope.row,
                  scope.row.stageStatistics[index],
                  'total'
                )}}},[_vm._v(_vm._s(scope.row.stageStatistics[index].total))])]}}],null,true)})],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }