<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="开始日期">
            <el-date-picker
              style="width: 100%"
              v-model="tableFilters.startDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy/MM/dd"
              @change="onDateChange"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="结束日期">
            <el-date-picker
              style="width: 100%"
              v-model="tableFilters.endDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy/MM/dd"
              @change="onDateChange"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="负责人(多选)">
            <BasicSelect
              multiple
              v-model="tableFilters.ownerIds"
              :allOptions="_ownerOptions"
              @change="onOwnerChange"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="部门(多选)">
            <el-cascader
              style="width: 100%"
              :options="_departmnetTreeOptions"
              v-model="tableFilters.departmentIds"
              collapse-tags
              :props="{
                multiple: true,
                emitPath: false,
                label: 'name',

                value: 'id',
                children: 'subs',
              }"
              clearable
              @change="onDepartmentChange"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="12">
          <el-form-item label="快速查询">
            <el-checkbox v-model="showCurrentDay" @change="checkCurrentDay"
              >显示今天</el-checkbox
            >
            <el-checkbox v-model="showCurrentMonth" @change="checkCurrentMonth"
              >显示本月</el-checkbox
            >
            <el-checkbox v-model="showCurrentAccount" @change="onCheckSelf"
              >只看自己</el-checkbox
            >
            <el-checkbox
              v-model="showCurrentDepartment"
              @change="onCheckSelfDepartment"
              >只看部门</el-checkbox
            >
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <!-- <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { lastDayOfMonth, startOfMonth, format } from "date-fns";
import { onClearFilter, GetDepartmentIdsBySelf } from "@/utils/common";
import tableFilter from "../mixins/tableFilter";
export default {
  components: {
    BasicSelect,
  },
  mixins: [tableFilter],
  data() {
    return {
      showMoreFilter: false,
      showCurrentMonth: false,
      showCurrentAccount: false,
      showCurrentDepartment: false,
      showCurrentDay: false,
    };
  },
  computed: {
    useInfo() {
      return this.$store.getters.userInfo;
    },
    _ownerOptions() {
      return this.$store.getters.ownerOptions;
    },
    _departmnetTreeOptions() {
      return this.$store.getters.departmnetTreeOptions;
    },
  },
  created() {
    this.showCurrentDay = true;
    this.checkCurrentDay();
  },
  methods: {
    onSearch() {
      this.$emit("change", this.tableFilters);
      this.$emit("confirm", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.showCurrentAccount = false;
      this.showCurrentDepartment = false;
      this.showCurrentMonth = false;
      this.showCurrentDay = false;
      this.$emit("change", this.tableFilters);
      this.$emit("clear", this.tableFilters);
    },
    checkCurrentDay() {
      if (!this.showCurrentDay) {
        this.tableFilters.startDate = "";
        this.tableFilters.endDate = "";
      } else {
        this.showCurrentMonth = false;
        this.tableFilters.startDate = format(new Date(), "yyyy/MM/dd");
        this.tableFilters.endDate = format(new Date(), "yyyy/MM/dd");
      }
      this.onSearch();
    },
    checkCurrentMonth() {
      if (!this.showCurrentMonth) {
        this.tableFilters.startDate = "";
        this.tableFilters.endDate = "";
      } else {
        this.showCurrentDay = false;
        this.tableFilters.startDate = format(
          startOfMonth(new Date()),
          "yyyy/MM/dd"
        );
        this.tableFilters.endDate = format(
          lastDayOfMonth(new Date()),
          "yyyy/MM/dd"
        );
      }
      this.onSearch();
    },
    onCheckSelf() {
      if (Object.keys(this.useInfo).length > 0) {
        this.tableFilters.ownerIds = this.showCurrentAccount
          ? [this.useInfo.id]
          : [];
      }
      this.onSearch();
    },
    onCheckSelfDepartment() {
      if (this.showCurrentDepartment) {
        GetDepartmentIdsBySelf()
          .then((data) => {
            this.tableFilters.departmentIds = data;
          })
          .catch((data) => {
            this.tableFilters.departmentIds = data;
          })
          .finally(() => {
            this.onSearch();
          });
      } else {
        this.tableFilters.departmentIds = [];
        this.onSearch();
      }
    },
    onDepartmentChange() {
      this.showCurrentDepartment = false;
    },
    onOwnerChange() {
      this.showCurrentAccount = false;
    },
    onDateChange() {
      this.showCurrentMonth = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin-top: 10px;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
}
</style>